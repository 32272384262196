<header>
  <div class="container">
    <a href="/" class="header-link">
      <img src="assets/img/logo-w.svg" alt="">
      <h1>portfolio</h1>
    </a>
    <div class="options">
      <button id="filter">
        <img src="assets/img/icons/filter.svg" alt="">
      </button>
      <button id="search">
        <img src="assets/img/icons/search.svg" alt="">
      </button>
    </div>
  </div>
</header>
