<li>
  <a href="{{ job.link }}" target="_blank" [ngStyle]="{'background-color': job.bgColor}">
    <div class="white"></div>
    <div class="content">
      <div class="text">
        <span class="index">{{ jobIndex }}</span>
        <h2>{{ job.title }}</h2>
      </div>
    </div>
    <span class="type">{{ job.type }}</span>
  </a>
</li>
